import React, { Suspense, lazy } from 'react';
import ErrorBoundary from './utils/ErrorBoundary';

const MainRoutes = lazy(() => import('./routers/MainRoutes'));

function App() {
  return (
    <div className="">
      <Suspense fallback={<div>Loading...</div>}>
        <ErrorBoundary>
          <MainRoutes />
        </ErrorBoundary>
      </Suspense>
    </div>
  );
}

export default React.memo(App);
